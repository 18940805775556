//import React, {useState, useEffect} from "react" //FuckAdBlock
import React, {useState, useEffect} from "react"
import './style.scss'
import { graphql } from "gatsby"

// import {
//   EmailShareButton,
//   FacebookShareButton,
//   LinkedinShareButton,
//   RedditShareButton,
//   TwitterShareButton,
//   WhatsappShareButton
// } from "react-share";
import { faFacebookF, faFacebook, faTwitter, faLinkedin, faLinkedinIn, faRedditAlien, faWhatsapp, faReddit, IconDefinition } from '@fortawesome/free-brands-svg-icons'
import {faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'


import { IArticlePageRequest } from "interfaces"
import {Layout, ArticleRowCmp, CategoryIcon} from 'components'
import {ArticleSplitter, ArticlePage, Page, ArticleRow} from 'types'
import AllMarkdownRemark from 'types/AllMarkdownRemarkSingleton'

// import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

// const ShareButtons = (
//   {art, className, circled} : {art: ArticlePage, className? : string, circled: boolean}
// ) => {

//   const butCls='m-md-1 m-p5'
//   let [onMobile, setOnMobile] = useState(false)
//   let innerWidth = typeof window === 'object' ? window.innerWidth : 2000

//   useEffect(() => {
//     setOnMobile(typeof window === 'object' ? window.innerWidth < 546 : false); //useWindowSize().width 
//   }, [innerWidth])

//   //< 546  //TODO: Use actual $bs-grid breakpoint for sm

//   const FaIcon = ({circleIcon, plainIcon} : {circleIcon: IconDefinition, plainIcon: IconDefinition}) => {

//     const size = !onMobile ? "2x" : "1x"

//     if (circled){
//       return (
//         <div className='faCircleContainer'>
          
//           <FontAwesomeIcon className='faCircle' size={size} icon={circleIcon} />
//         </div>
//       )
//     }else{ 
//       return ( 
//         <div className="notCircledContainer">
//           <FontAwesomeIcon icon={plainIcon} />
//         </div>
//       )
//     }
//   }

//   return (
//     <div className={`${className} d-flex`}>
//       <FacebookShareButton 
//         className={butCls}
//         url={art.url}
//       >
//         <FaIcon circleIcon={faFacebookF} plainIcon={faFacebook} />
//       </FacebookShareButton>
      
//       <TwitterShareButton 
//         className={butCls}
//         url={art.url}
//         title={art.shareTitle}
//         hashtags={["SuddenlySaskatchewan", "SuddenlySask"]}
//         // related="@SuddenlySask" 
//       >
//         <FaIcon circleIcon={faTwitter} plainIcon={faTwitter} />
//       </TwitterShareButton>
      
//       <LinkedinShareButton
//         className={butCls}
//         url={art.url}
//         title={art.shareTitle}
//         summary={art.description} //Doesn't show up
//         source={art.url}
//       >
//         <FaIcon circleIcon={faLinkedinIn} plainIcon={faLinkedin} />
//       </LinkedinShareButton>
      
//       {/* <PinterestShareButton
//         className={butCls}
//         url={siteUrl}
//         media={shareImageUrl}
//         description={description} //doesn't show up
//       >
//         <FontAwesomeIcon icon={faPinterest} />
//       </PinterestShareButton> */}
      
//       <RedditShareButton
//         className={butCls}
//         url={art.url}
//         title={art.shareTitle}
//       >
//         <FaIcon circleIcon={faRedditAlien} plainIcon={faReddit} />
//       </RedditShareButton>
      
//       <WhatsappShareButton  //TODO: test this
//         className={butCls}
//         url={art.url}
//         title={art.title}
//       >
//         <FaIcon circleIcon={faWhatsapp} plainIcon={faWhatsapp} />
//       </WhatsappShareButton>
      
//       <EmailShareButton 
//         className={butCls}
//         url={art.url}
//         subject={`${art.shareTitle} | Suddenly Saskatchewan`}
//         body={art.description}
//         separator={"\n\n"}
//       >
//         <FaIcon circleIcon={faEnvelopeOpen} plainIcon={faEnvelopeOpen} />
//       </EmailShareButton>    
//     </div>
//   )
// }

const ArticleBanner = (
  {art, className} : {art:ArticlePage, className:string}
) => (
  <div  className={className}>
    <small id="articleDate" ><p className='mb-2'>{new Date(art.date).toDateString()}</p></small>
    <CategoryIcon category={art.category} className="articleCategoryIcon" />
    <h1 className='titleOfArticle'>{art.title}</h1>
    <h2 className='articleSubTitle'>{art.subTitle}</h2>

    {art.author && <small><p className='writtenBy'>Written by {art.author}</p></small>}
    <div className="shareBlock">
      <hr className='shareButtonHr'/>
      {/* <ShareButtons 
        art={art}
        circled={true}
      /> */}
      <hr className='shareButtonHr'/>
    </div>
  </div>
)

//TODO: Coda: something that can autocomplete arguments maybe

const ArticlePageCmp = ({ data, location }: { data: IArticlePageRequest, location: any }) => {

  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])

  const art = new ArticlePage(data)
  
  const row = new ArticleRow(
    ArticleSplitter.getArticlesForSection(AllMarkdownRemark.allMarkdownRemark.edges, art.category, art.subCategory, art.title)
  )
  
  return (
    <Layout siteMetadata={art.metaData} page={Page.article}>
      <div id="top" className='w-100'>
        <ArticleBanner art={art} className='articleBanner' />
      </div>

      <div className='articleContainer '>
        <div className='sideJellyL col-lg-2' />
        <div className='col-lg-6 col-11 m-auto'>  
          <div id="article-content" dangerouslySetInnerHTML={{ __html: art.html }} />
          {/* <ShareButtons art={art} circled={false} /> */}
        </div>
        <div className='sideJellyR' />
      </div>

      <div className='col-lg-8 mt-5 mb-0 mx-auto col-11'>
        <h2 id='otherArticles' className='mt-3'>OTHER ARTICLES</h2>
        <hr className='mb-5'></hr>
        <ArticleRowCmp row={row}  /> 
      </div>
      
    </Layout>
  )
}

export default ArticlePageCmp

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      fields {
        slug
      }
      excerpt
      html
      frontmatter {
        title
        subTitle
        date(formatString: "MMMM DD, YYYY  hh:mm a")
        author
        category
        description
        featured
        subCategory
        featuredImage {
          publicURL
        }
      }
    }
    site{
      siteMetadata{
        siteUrl
      }
    }
  }
`
