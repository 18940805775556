import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { IAllMarkdownRemark } from 'interfaces/requests.interface';

class AllMarkdownRemarkSingleton{
    
    private static _allMarkdownRemark: IAllMarkdownRemark 

    private static getAllMarkdownRemark(){
        this._allMarkdownRemark = useStaticQuery(graphql`
            query AllMarkdownQuery{
                allMarkdownRemark{
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                subTitle
                                date(formatString: "MMMM DD, YYYY")
                                category
                                subCategory
                                givenRank
                                featuredImage {
                                    childImageSharp {
                                        fluid(maxWidth: 400) {
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`).allMarkdownRemark
    }

    public static get allMarkdownRemark(): IAllMarkdownRemark{
        if (this._allMarkdownRemark){
            return this._allMarkdownRemark
        }else{
            this.getAllMarkdownRemark()
            return this._allMarkdownRemark
        }
    }
}

export default AllMarkdownRemarkSingleton